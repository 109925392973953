import { Controller } from 'stimulus';
import Marquee from 'simple-marquee';
const {
  MarqueeGroup
} = Marquee;


export default class extends Controller {
  connect() {
    debugger
  }
}