import ItemController from './item_controller';

export default class extends ItemController {
  connect(){
    this.element.addEventListener('picker--daterange:change', this.handleChange.bind(this));
  }

  handleChange(e) {
    const token = e.detail.value.split('-');
    const value = [token[0].trim(), token[1].trim()];
    const checked = true;

    const event = new CustomEvent('filters:select', {
      detail: {
        field: this.fieldName,
        value: value,
        status: checked
      }
    });
    this.container.dispatchEvent(event);
  }
}
