import ItemController from './item_controller';

export default class extends ItemController {
  connect(){
    this.element.noUiSlider.on('update', this.handleChange.bind(this));
  }

  handleChange(values,handle) {
    const value = [];
    values.forEach(x => {
      value.push(parseInt(x));
    })
    const checked = true;

    const event = new CustomEvent('filters:select', {
      detail: {
        field: this.fieldName,
        value: value,
        status: checked
      }
    });
    this.container.dispatchEvent(event);
  }
}
