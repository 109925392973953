import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  initialize(){
    this.fieldName = this.data.get('fieldName');    
  }

  connect(){
    this.inputTargets.forEach(x => {
      x.addEventListener('change', this.handleChange.bind(this));
    });
  }

  handleChange(e){
    const sourceInput = e.srcElement;
    const value = sourceInput.value;
    const checked = sourceInput.checked;

    const event = new CustomEvent('filters:select', {
      detail: {
        field: this.fieldName,
        value: value,
        status: checked
      }
    });
    this.container.dispatchEvent(event);
  }

  get container(){
    return document.querySelector(this.data.get('containerSelector'));
  }
}
