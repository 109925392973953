import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this._path = this.data.get('path');
  }

  connect(){
    this.element.addEventListener('filters:select', this.handleFilterSelect.bind(this));
    this.interceptPagination();
  }

  handleFilterSelect(e){
    this.adjustPayload(e.detail);
    window.Ajax.get(this.path, this.ajaxOptions());
  }

  handleSuccess(responseText){
    eval(responseText);
    this.interceptPagination();
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }
  handleDone(){
  }

  adjustPayload(detail){
    if(detail.field === 'regulation_type'){
      if(detail.status){
        this.payload.regulation_types.push(detail.value)
      } else {
        const index = this.payload.regulation_types.indexOf(detail.value);
        this.payload.regulation_types.splice(index,1);
      }
    }
    if(detail.field === 'decision_type'){
      if(detail.status){
        this.payload.decision_types.push(detail.value)
      } else {
        const index = this.payload.decision_types.indexOf(detail.value);
        this.payload.decision_types.splice(index,1);
      }
    }
    if(detail.field === 'categories'){
      if(detail.status){
        this.payload.categories.push(detail.value)
      } else {
        const index = this.payload.categories.indexOf(detail.value);
        this.payload.categories.splice(index,1);
      }
    }
    if(detail.field === 'years'){
      this.payload.years = detail.value
    }
    if(detail.field === 'daterange'){
      this.payload.date = detail.value
    }
    if(detail.field === 'search'){
      this.payload.search = detail.value
    }
    if(detail.field === 'number'){
      this.payload.number = detail.value
    }
  }

  interceptPagination(){
    const pageLinks = document.querySelectorAll('.pagination > .page-item > .page-link');
    pageLinks.forEach(pageLink => {
      pageLink.addEventListener('click',(e) => {
        e.preventDefault();
        let href = e.srcElement.href;
        if(!href.match(/\.js/)){
          const token = href.split('?');
          href = `${token[0]}.js?${token[1]}`;
          window.Ajax.get(href, this.ajaxOptions());
        } else {
          window.Ajax.get(href, this.ajaxOptions());
        }
      });
    })
  }

  ajaxOptions(){
    return {
      headers: [
        {
          key: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
    }
  }

  get payload(){
    if(this._payload){
      return this._payload;
    }
    this._payload = {
      regulation_types: [],
      decision_types: [],
      categories: [],
      date: null,
      number: null,
      search: null,
      years: null,
    };
    return this._payload;
  }

  get path(){
    const appliedFilters = [`page=${this.currentPage}`];
    const queryValues = Object.values(this.payload);
    for(let i=0; i<queryValues.length; i++){
      const values = queryValues[i]
      const key = Object.keys(this.payload)[i];

      if(!values){
        continue;
      }
      if(values instanceof Array){
        for(let j=0; j<values.length; j++){
          appliedFilters.push(`${key}[]=${values[j]}`);
        }
      }
      if(typeof values === 'string'){
        appliedFilters.push(`${key}=${values}`);
      }
    }

    return `${this._path}?${encodeURI(appliedFilters.join('&'))}`;
  }

  get currentPage(){
    const activedPage = document
      .querySelector('.pagination > .page-item.active > a')
    if(!activedPage){
      return 0;
    }
    return activedPage.textContent;
  }
}
