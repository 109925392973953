import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.minYear = this.data.get('minYear');
    this.maxYear = this.data.get('maxYear');
    this.startDate = this.data.get('startDate');
    this.endDate = this.data.get('endDate');
  }

  connect(){
    $(this.element).daterangepicker(this.options, this.handleChange.bind(this));
  }

  handleChange(start, end, label){
    $(this.element.querySelector('.form-control'))
      .val(`${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`);

    this.dispatchEventToSelf(start,end);
  }

  dispatchEventToSelf(start,end){
    const event = new CustomEvent('picker--daterange:change', {
      detail: {
        value: `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`
      }
    });
    this.element.dispatchEvent(event);
  }

  get options(){
    const result = {
      buttonClasses: ' btn',
      applyClass: 'btn-primary',
      cancelClass: 'btn-secondary',
      timePicker: false,
      locale: {
        format: 'DD/MM/YYYY'
      },
      showDropdowns: true
    }

    if(this.minYear){
      result['minYear'] = parseInt(this.minYear);
    }
    if(this.maxYear){
      result['maxYear'] = parseInt(this.maxYear);
    }
    if(this.startDate){
      result['startDate'] = this.startDate;
    }
    if(this.endDate){
      result['endDate'] = this.endDate;
    }

    return result;
  }
}
