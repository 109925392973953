import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.min = parseInt(this.data.get('min'));
    this.max = parseInt(this.data.get('max'));
  }

  connect(){
    noUiSlider.create(this.element, this.options);
  }

  get options(){
    return {
      start: [this.min, this.max],
      connect: true,
      tooltips: true,
      format: wNumb({
        decimals: 0
      }),
      direction: 'ltr',
      range: {
        'min': this.min,
        'max': this.max
      },
      step: 1,      
    }
  }
}
