import ItemController from './item_controller';

export default class extends ItemController {
  connect(){
    this.number = this.element.querySelector("#number");
    this.number.addEventListener('change', this.handleChangeUpdate.bind(this));    
  }

  handleChangeUpdate(){    
    const value = this.number.value
    const checked = true;

    const event = new CustomEvent('filters:select', {
      detail: {
        field: "number",
        value: value,
        status: checked
      }
    });
    this.container.dispatchEvent(event);
  }

  scrollToTop(){
    window.scrollTo(0,0);
  }
}
