import ItemController from './item_controller';

export default class extends ItemController {
  connect(){
    $(this.element).on('select2:select', this.handleChange.bind(this))
  }

  handleChange(e) {
    const value = this.element.value;
    const checked = true;

    const event = new CustomEvent('filters:select', {
      detail: {
        field: this.fieldName,
        value: value,
        status: checked
      }
    });
    this.container.dispatchEvent(event);
  }
}
