import ItemController from './item_controller';

export default class extends ItemController {
  handleChange(){
    const value = this.inputElement.value;
    const checked = true;

    const event = new CustomEvent('filters:select', {
      detail: {
        field: this.fieldName,
        value: value,
        status: checked
      }
    });
    this.container.dispatchEvent(event);
    // this.scrollToTop();
  }

  scrollToTop(){
    window.scrollTo(0,0);
  }

  get inputElement(){
    return document.querySelector('input[type="text"][name="keyword"]');
  }
}
